import React from "react"
import Layout from "../components/layout"
import { Banner, BannerStyle, Content, TextOOU } from "@igloonet-web/shared-ui"
import { Helmet } from "react-helmet"

const OchranaOsobnichUdaju: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Ochrana osobních údajů | igloonet </title>
      <meta
        name="description"
        content="Zásady ochrany osobních údajů v igloonetu."
      />
      <script>
        {`var script = document.createElement("script"); script.src =
        "https://consent.cookiebot.com/320c3ac6-44b9-403f-9371-cc00c5809c75/cd.js";
        script.async = "true";
        document.getElementById('CookieDeclarationTag').appendChild(script);`}
      </script>
    </Helmet>

    <Banner
      style={BannerStyle.Reference}
      orangeHeading="Zásady ochrany"
      blackHeading="osobních údajů"
    />

    <Content>
      <div id="CookieDeclarationTag" />
      <TextOOU />
    </Content>
  </Layout>
)

export default OchranaOsobnichUdaju
